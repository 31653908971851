import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/store'
import axios from 'axios'
import governorates from './routes/governorates'
import delegations from './routes/delegations'
import localities from './routes/localities'
import repositories from './routes/repositories'
import cars from './routes/cars'
import suppliers from './routes/suppliers'
import deliveryMans from './routes/deliveryMans'
import managers from './routes/managers'
import orders from './routes/orders'
import pickups from './routes/pickups'
import deliveryNotes from './routes/deliveryNotes'
import roadmaps from './routes/roadmaps'
import payments from './routes/payments'
import invoices from './routes/invoices'
import transferVouchers from './routes/transferVouchers'
import returnVouchers from './routes/returnVouchers'
import weights from './routes/weights'
import reporting from './routes/reporting'
import exchange from './routes/exchange'
import requestPartner from './routes/requestPartner'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'dashboard',
      props: true,
      component: () => import('@/views/Home.vue'),
      meta: {
        layout: 'vertical',
        requireLogin: true,
        pageTitle: 'Tableau de bord',
        breadcrumb: [
          {
            text: 'Tableau de bord',
            active: true,
          },
        ],
      },
    },
    {
      path: '/profil',
      name: 'profile',
      component: () => import('@/views/Authentification/Profil.vue'),
      meta: {
        requireLogin: true,
      },
    },
    {
      path: '/login',
      name: 'auth',
      component: () => import('@/views/Authentification/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('@/views/Authentification/Logout.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-403',
      name: 'error-403',
      component: () => import('@/views/error/Error403.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
    ...governorates,
    ...delegations,
    ...repositories,
    ...cars,
    ...suppliers,
    ...deliveryMans,
    ...managers,
    ...orders,
    ...pickups,
    ...deliveryNotes,
    ...requestPartner,
    ...roadmaps,
    ...payments,
    ...invoices,
    ...transferVouchers,
    ...returnVouchers,
    ...weights,
    ...localities,
    ...reporting,
    ...exchange,
  ],
})

router.beforeEach((to, from, next) => {
  const { token, role } = store.state

  if (token) {
    axios.defaults.headers.common.Authorization = `Token ${token}`
  } else {
    axios.defaults.headers.common.Authorization = ''
  }

  if (to.matched.some(record => record.meta.requireLogin)) {
    if (!store.state.isAuthenticated) {
      next({ name: 'auth' })
    } else if (to.meta.roles && !to.meta.roles.includes(role)) {
      // Redirect to an error page or any appropriate action
      next({ name: 'error-403' }) // Unauthorized access
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
