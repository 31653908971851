export default [
  {
    path: '/request-partner',
    name: 'request-partner-list',
    props: true,
    component: () => import('@/views/requestPartner/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Liste des demandes de partenaire',
      breadcrumb: [
        {
          text: 'Demande de partenaire',
        },
        {
          text: 'Liste des demandes de partenaire',
          active: true,
        },
      ],
      roles: ['admin'],
    },
  },
]
